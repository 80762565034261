<template>
<PreviewLayout
  class="document-preview"
  :loaded="loaded"
  :background-color="backgroundColor"
  color="white"
  @close="$emit('close')">

  <template v-if="loaded">

    <div
      slot="actions"
      class="message-options">

      <IconLink
        @clicked="editRecord">
        edit
      </IconLink>

    </div>

    <template
      class="document-header"
      slot="info">
      <h3 class="label">
        {{ data.label }}
      </h3>
      <h5 class="type">
        {{ documentType }}
      </h5>
    </template>


    <PanelLayout
      slot="panels"
      label="description"
      :open.sync="panels.description"
      >
      <EditableDiv
        class="memo"
        :value="data.description"
        placeholder="Add a document description"
        @updated="updateField('description', $event)"/>
    </PanelLayout>

    <FilesPanel
      slot="panels"
      :list="data.files_list"
      :api="`documentfiles/link/${fId}`"
      :open.sync="panels.files"
      @updated="refreshData"/>

    <!-- <NotesPanel -->
    <!--   slot="panels" -->
    <!--   :list="data.notes_list" -->
    <!--   :open="true"/> -->

  </template>
</PreviewLayout>
</template>

<script>
import RecordDataMixin from '@acorns-ui/Previews/Mixins/RecordDataMixin';
import PreviewLayout from '@acorns-ui/Layouts/PreviewLayout'
import PanelLayout from '@acorns-ui/Layouts/PanelLayout';
import EditableDiv from '@acorns-ui/Text/EditableDiv';
//import TextAvatar from '@/components/Avatars/textAvatar';
//import NotesPanel from '@/components/Panels/notesPanel';
//import CommentPanelItem from '@/components/Items/commentPanelItem';
//import ProjectsPanel from '@/components/Panels/projectsPanel';
//import PropertiesPanel from '@/components/Panels/propertiesPanel';
//import IssuesPanel from '@/components/Panels/issuesPanel';
import FilesPanel from '@acorns-ui/Panels/FilesPanel';
import NotesPanel from '@acorns-ui/Panels/NotesPanel';
import IconLink from '@acorns-ui/Icons/IconLink';
//import EditableText from '@/components/Forms/inputs/editableText';


export default {
  name: 'DocumentPreview',
  mixins: [
    RecordDataMixin,
  ],
  props: {
    dataset: { default: 'documents' },
    d: { required: false },
    prid: { type: [String, Number] },
    store: { type: String, default: 'documents' },
    fkey: { type: String, default: 'document_id' },
    editable: { type: Boolean, default: true }, // parental control
  },
  data() {
    return {
      notes: [],
      signature: [],
      panels: {
        description: true,
        files: true,
      }
    };
  },
  components: {
    PreviewLayout,
    EditableDiv,
    //EditableText,
    PanelLayout,
    FilesPanel,
    IconLink,
    NotesPanel,
  },
  computed: {
    documentDescription() {
      return this.data.description || 'Add description';
    },
    noteCount() {
      if (!this.data.notes_list) return 0;
      return this.data.notes_list.length;
    },
    documentType() {
      return this.data.document_type.label;
    },
    mediaType() {
      if (this.data.media_type && this.data.media_type.media_type) return this.data.media_type.media_type;
      if (this.data.media_type) return this.data.media_type;
      if (this.data.media) return this.data.media.media_type;
      return '';
    },
    backgroundColor() {
      return '#3c7ea6';
    },
  },
  methods: {
    addNote() {
      this.$store.commit('SHOW_MODAL', {
        name: 'new-link-form',
        label: 'New',
        params: { type: 'documentnotes', args: { document_id: this.prid }, callback: this.refreshData },
      });
    },
    updateNote(id, comment) {
      const action = `${this.store}/updateChildItem`;
      this.$store.dispatch(action, { child: 'comments', data: { id, comment } });
      //
    },
  },
};
</script>


<style lang="scss">
@import '~@/styles/variables';

.document-preview {
  background-color: white;
  // width: 500px;
  display: flex;
  flex-direction: column;

  &>.header {
    padding: 0.5rem;
  }

  h3 {
    margin: 0;
    padding: 0;
  }
  h5 {
    margin: 0.5rem 0;
    padding: 0;
  }


}
</style>
