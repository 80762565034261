<template>
<PreviewLayout
  class="issue-preview"
  :loaded="loaded"
  @close="$emit('close')">

  <template v-if="loaded">

    <template slot="actions">
      <RoutePageIcon
        type="issues"
        :id="data.issue_id"
        title="Open issue page"
        @followed="$emit('close')"
        />

    </template>

    <template
      class="issue-header"
      slot="info">
      <h2
        class="label">
        {{ data.label }}
      </h2>
      <NestedTypeLabel
        :id="issueType.issue_type_id"
        :label="issueType.label"
        :parents="issueType.parents"
        sep="br"/>
    </template>


    <div class="body-content">
      <div class="details">
        <InfoLine
          @click="editField('lead_contact_id')"
          title="lead">
          <TextAvatar :contact="data.lead"/>
        </InfoLine>
        <!-- <InfoLine -->
        <!--   @click="editField('priority_id')" -->
        <!--   v-if="data.priority" -->
        <!--   title="priority"> -->
        <!--   <span>{{ data.priority.label }}</span> -->
        <!-- </InfoLine> -->
        <InfoLine
          @click="editField('discovered_on')"
          title="discovered">
          <span>{{ data.discovered_on | date }}</span>
        </InfoLine>
        <InfoLine
          @click="editField('resolved_on')"
          v-if="data.resolved_on"
          title="resolved">
          <span>{{ data.resolved_on | date }}</span>
        </InfoLine>
        <InfoLine title="status">
          <span>{{ data.status }}</span>
        </InfoLine>
        <InfoLine
          v-if="data.project"
          title="project">
          <span>{{ data.project.label }}</span>
        </InfoLine>
        <InfoLine
          v-if="data.property"
          title="unit">
          <PropertyChip v-bind="data.property"/>
        </InfoLine>
      </div>

      <div
        slot="panels"
        class="children">

        <PanelLayout
          :open.sync="panels.descripition"
          label="description">
          <EditableDiv
            class="memo"
            :value="data.description"
            @updated="updateField('description', $event)"/>
        </PanelLayout>

        <PanelLayout
          :open.sync="panels.remedy"
          label="Proposed remedy">
          <EditableDiv
            class="memo"
            :value="data.proposal"
            @updated="updateField('proposal', $event)"/>
        </PanelLayout>

        <NotesPanel
          parent="issue"
          :parent-id="fId"
          :list="data.notes_list"
          :params="{ type: 'issuenotes' ,
                   args: { issue_id : prid },
                   callback: refreshData }"
          :open.sync="panels.notes"
          @updated="refreshData"
          />

        <DocumentsPanel
          parent="issue"
          :parent-id="fId"
          :list="data.documents_list"
          :api="`issuefiles/link/${prid}`"
          :open.sync="panels.documents"
          @updated="refreshData"
          />

        <TasksPanel
          parent="issue"
          :parent-id="fId"
          :params="{ type: 'issuetasks',
                   args: { issue_id : prid },
                   callback: refreshData }"
          :list="data.tasks_list"
          :open.sync="panels.tasks"
          @updated="refreshData"
          />

        <MessagesPanel
          parent="issue"
          :parent-id="fId"
          :list="data.messages_list"
          :open.sync="panels.messages"
          :params="{ type: 'issuemessages',
                   args: { issue_id : prid },
                   callback: refreshData }"
          @removeItem="removeChildItem"
        @updated="refreshData"
        />

        <ContactsPanel
          parent="issue"
          :parent-id="fId"
          :list="data.contacts_list"
          :params="{ type: 'issuecontacts',
                   args: { issue_id : prid },
                   callback: refreshData }"
          :open.sync="panels.contacts"
        @updated="refreshData"
        />

        </div>
      </div>
    </template>
</PreviewLayout>
</template>

<script>
import RecordDataMixin from '@acorns-ui/Previews/Mixins/RecordDataMixin';
import PreviewLayout from '@acorns-ui/Layouts/PreviewLayout'
import TextAvatar from '@acorns-ui/Avatars/TextAvatar';
import GroupTextAvatar from '@acorns-ui/Avatars/GroupTextAvatar';
import Icon from '@acorns-ui/Icons/FontIcon';
import PropertyChip from '@acorns-ui/Chips/PropertyChip';
import TasksPanel from '@acorns-ui/Panels/TasksPanel';
import NotesPanel from '@acorns-ui/Panels/NotesPanel';
import MessagesPanel from '@acorns-ui/Panels/MessagesPanel';
import ContactsPanel from '@acorns-ui/Panels/ContactsPanel';
import DocumentsPanel from '@acorns-ui/Panels/DocumentsPanel';
import NestedTypeLabel from '@acorns-ui/Text/NestedTypeLabel';
import InfoLine from '@acorns-ui/Text/InfoLine';
import RoutePageIcon from '@acorns-ui/Icons/RoutePageIcon';

//import PanelList from '@/components/containers/panelList-ga';



//import DetailLine from './detailLine';

import EditableDiv from '@acorns-ui/Text/EditableDiv';
import PanelLayout from '@acorns-ui/Layouts/PanelLayout';

//import FilesPanel from '@/components/Panels/filesPanel';


//import RestrictionsPanel from '@/components/Panels/restrictionsPanel';
//import ProposalPanelItem from '@/components/Items/proposalPanelItem';


export default {
  name: 'IssuePreview',
  mixins: [
    RecordDataMixin,
  ],
  props: {
    d: { type: Object, required: false },
    store: { type: String, default: 'issues' },
    dataset: { type: String, default: 'issues'},
    pkey: { type: String, default: 'issue_id' },
    fkey: { type: String, default: 'issue_id' },
    editable: { type: Boolean, default: true }, // parental control
  },
  data(){
    return {
      panels: {
        description: true,
        remedy: true,
        notes: true,
        documents: true,
        tasks: true,
        messages: true,
        contacts: true,
      }
    }
  },
  components: {
    PreviewLayout,
    PanelLayout,
    EditableDiv,
    TasksPanel,
    NotesPanel,
    MessagesPanel,
    ContactsPanel,
    DocumentsPanel,
    //RestrictionsPanel,
    //ProposalPanelItem,
    Icon,
    InfoLine,
    TextAvatar,
    PropertyChip,
    NestedTypeLabel,
    RoutePageIcon,
  },
  computed: {
    issueType() {
      if (this.data.type) return this.data.type;
      if (this.data.issue_type) return this.data.issue_type;
    },
   // priorityClass() {
   //   const pre = this.data.priority.label || 'uk';
   //   return `${pre.toLowerCase()}-priority`;
   // },
    additionalInfo(){
      return this.data.additional_info || {};
    }
  },
  methods: {
    viewReport(){
      // open report in modal
      },
    formatTitle(title) {
      return title.replace(/_/g, ' ');
    },
  },
};
</script>


<style lang="scss">
@import '~@/styles/variables';

.issue-preview {
  background-color: white;
  display: flex;
  flex-direction: column;

  &>.header {
    padding: 0 0.5rem;
    h2 {
      font-size: $header2;
      line-height: $header2;
      margin: 0.5rem 0;
      padding: 0;
    }
  }

  .overflow-container {
    &>.details {
      flex: 1;
      border-bottom: 1px solid #e1e2e4;
    }
  }

  .body-content {
    overflow-y: auto;
  }

  .memo {
    padding: 5px 10px;
  }

  &>.details {
    // flex: 1;
    border-bottom: 1px solid #e1e2e4;
  }
  .children {
    overflow-y: auto;

    .panel-body {
      background-color: rgba(0,0,0,0.05);
    }
  }

}
</style>
