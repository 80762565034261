import ModalStandin from './ModalStandin';
import CollectionForm from '@acorns-ui/Forms/CollectionForm';
import ProjectForm from '@acorns-ui/Forms/ProjectForm';
import NoteForm from '@acorns-ui/Forms/NoteForm';
import ContactForm from '@acorns-ui/Forms/ContactForm';
import DocumentForm from '@acorns-ui/Forms/DocumentForm';
import MessageForm from '@acorns-ui/Forms/MessageForm';
import IssueForm from '@acorns-ui/Forms/IssueForm';
import TaskForm from '@acorns-ui/Forms/TaskForm';
import WorkForm from '@acorns-ui/Forms/WorkForm';
import FileForm from '@acorns-ui/Forms/FileForm';
import HoursForm from '@acorns-ui/Forms/HoursForm';
import AttributeForm from '@acorns-ui/Forms/AttributeForm';
import FileReview from '@acorns-ui/Forms/FileReview';
import DeliverableForm from '@acorns-ui/Forms/DeliverableForm';
import ObjectRoleForm from '@acorns-ui/Forms/ObjectRoleForm';
import EmailForm from '@acorns-ui/Forms/EmailForm';
import AddressForm from '@acorns-ui/Forms/AddressForm';
import PhoneForm from '@acorns-ui/Forms/PhoneForm';
import ReplyForm from '@acorns-ui/Forms/ReplyForm';
import SelectPropertyForm from '@acorns-ui/Forms/SelectPropertyForm';
import PropertyForm from '@acorns-ui/Forms/PropertyForm';
import VisitForm from '@acorns-ui/Forms/VisitForm';
import LeadForm from '@acorns-ui/Forms/LeadForm';
import CollectionSelectForm from '@acorns-ui/Forms/CollectionSelectForm';
import StatusForm from '@acorns-ui/Forms/StatusForm';
import ObjectFieldForm from '@acorns-ui/Modules/CollectionModule/ObjectFieldForm';

import PreviewFactory from '@/components/Previews/PreviewFactory';


export default {
  props: {
    defaultComponent: {
      type: String,
      default: 'ModalStandin',
    }
  },
  components: {
    ModalStandin,
    CollectionForm,
    StatusForm,
    NoteForm,
    ProjectForm,
    AddressForm,
    EmailForm,
    PhoneForm,
    ReplyForm,
    ObjectRoleForm,
    PropertyForm,
    ProjectForm,
    HoursForm,
    DocumentForm,
    AttributeForm,
    FileForm,
    FileReview,
    PreviewFactory,
    CollectionForm,
    NoteForm,
    MessageForm,
    ContactForm,
    DeliverableForm,
    SelectPropertyForm,
    IssueForm,
    TaskForm,
    WorkForm,
    VisitForm,
    LeadForm,
    ObjectFieldForm,
    CollectionSelectForm,
  }
};
