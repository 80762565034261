import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.directive('clickoutside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line
    el.event = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.event);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.event);
  },
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
