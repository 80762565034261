<template>
<component
  v-if="internalData || prid"
  :editable="editable"
  :showMap="showMap"
  :is="componentName"
  :d="internalData"
  :prid="prid"
  :store="store"
  :dataset="dataset"
  :collection="collection"
  :parent="parent"
  :parent-id="id"
  :callback="callback"
  @close="$emit('close', $event)"
  @updated="$emit('updated', $event)"
  class="preview" />
</template>

<script>
//import { mapMutations, mapGetters, mapState } from 'vuex';
//import genericPreview from './genericPreview';

import ProjectPreview from '@acorns-ui/Previews/ProjectPreview';
import TaskPreview from '@acorns-ui/Previews/TaskPreview';
import ContactPreview from '@acorns-ui/Previews/ContactPreview';
import CommunicationPreview from '@acorns-ui/Previews/CommunicationPreview';
import GenericPreview from '@acorns-ui/Previews/GenericPreview';

import NotePreview from './notePreview';
//import propertyPreview from './propertyPreview';

import IssuePreview from './issuePreview';
//import filePreview from './filePreview';
import DocumentPreview from './documentPreview';
//import regionPreview from './regionPreview';
import HourPreview from './hourPreview';
//import eventPreview from './EventPreview';
//import featurePreview from './featurePreview';


// import photoPointPreview from './photoPointPreview';
// import cameraPointPreview from './cameraPointPreview';
// import standsPreview from './standsPreview';
// import parcelPreview from './parcelPreview';
// //import parcelPreview from './parcelPreview';
// import approvalPreview from './approvalPreview';
// import propertyStatusPreview from './propertyStatusPreview';
// import monitoringElementPreview from './monitoringElementPreview';

const collectionPreviews = {
  'tasks': 'TaskPreview',
  'contacts': 'ContactPreview',
  'properties': 'propertyPreview',
  'parcels': 'parcelPreview',
  'communications': 'CommunicationPreview',
  'notes': 'NotePreview',
  'issues': 'IssuePreview',
  'regions': 'regionPreview',
  'events': 'eventPreview',
  'projects': 'ProjectPreview',
  'hours': 'HourPreview',
  'documents': 'DocumentPreview',
  'elements': 'monitoringElementPreview',
};

const snakeToCamel = (str) => str.replace(
    /(^[a-z]|[-_][a-z])/g,
    (group) => group.toUpperCase()
                    .replace('-', '')
                    .replace('_', '')
);

export default {
  name: 'PreviewFactory',
  props: {
    parent: {
      type: String,
      required: false,
    },
    showMap: {
      type: Boolean,
      required: false,
      default: true,
    },
    id: {
      type: [Number, String],
      required: false,
    },
    prid: {
      type: [Number, String],
      required: false,
    },
    editable: { type: Boolean, default: true },
    component: { required: false },
    store: String, // this is the parent of the dataset or workspace
    dataset: { required: false }, // latest iteration of identifying the preview
    collection: {
      required: false,
      type: String,
    }, // new latest iteration of identifying the preview
    data: { type: Object, required: false },
    callback: {
      type: Function,
      default: res => console.log(res),
    },
  },
  components: {
    GenericPreview,
    //approvalPreview,
    ContactPreview,
    //eventPreview,
    //featurePreview,
    //filePreview,
    HourPreview,
    IssuePreview,
    CommunicationPreview,
    NotePreview,
    ProjectPreview,
    //propertyPreview,
    // regionPreview,
    TaskPreview,
    DocumentPreview,
  },
  created() {
    //console.log('created prev factory', this.prid, this.collection);
    // 'type:', this.type, this.componentName, this.id, this.internalData);
  //  if (this.id) { // greg hack
  //    this.$store.commit('searchPage/CURRENT_SELECTION', this.id);
  //  }
  },
  //watch: {
  //  id(newId, oldId) {
  //    // console.log("NEW ID", newId, oldId)
   //   this.$store.commit('searchPage/CURRENT_SELECTION', newId);
   // },
  //},
  computed: {
   // ...mapGetters({
   //   selectedData: 'searchPage/currentSelectedData',
   // }),
   // ...mapState('searchPage', [
   //   'meta',
   // ]),
    internalData() {
      if (this.data) {
        return this.data;
      }
      return this.selectedData;
    },
    componentString() {
      if (this.component) {
        return this.component;
      } else if (this.collection) {
        return collectionPreviews[this.collection] || 'genericPreview';
      } else if (this.dataset) {
        return `${this.datasetSingularName}Preview`
      } else if (this.type) {
        const type = this.type.replace(/s$/,'')
        return `${type}Preview`
      } else if (this.meta && this.meta.templates) {
        return this.meta.templates.preview;
      }
    },
    datasetSingularName(){
      const ds = this.$store.state[this.dataset];
      if(ds) return ds.record;
      return this.dataset;
    },
    genericPreviewName(){
      return this.showMap ? 'generic-preview' : 'feature-preview';
    },
    componentPath() {
      return snakeToCamel(this.componentString);
    },
    componentList() {
      return this.$options.components;
    },
    componentName() {
      const cs = this.componentString;
      const csPath = snakeToCamel(cs);
      if (this.$options.components[csPath]) {
        return cs;
      }
      return this.genericPreviewName;
    },
  },
  methods: {
    //   ...mapMutations([SHOW_MODAL]),
    // editRecord() {
    //   const type = this.type.replace(/-preview/g, '');
    //   const args = { type, id: this.id };

    //   this.SHOW_MODAL({
    //     name: 'edit-form',
    //     params: args,
    //   });
    // },
  },
};
</script>

<style lang="scss">
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .preview {
    @include formsize;
  }
}
.modal-container.preview {
  position: relative;

  &>.header {
    position: absolute;
    background-color: transparent;
    border-bottom: none;
    width: 100%;
    z-index: 100;
    .icon {
      color: rgba(255,255,255,0.7);
    }
  }
  #modal-content {
    padding: 0px;
  }

  .preview {
    max-height: 95vh;

    &>.toolbar {
      padding: 10px;
      right: inherit;
    }
  }
}

</style>
