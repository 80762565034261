<template>
<div class="note-preview">
  <LoadingIndicator v-if="!loaded"/>
  <template v-if="loaded">
    <div class="header">
      <div class="toolbar">
        <div class="actions">
          <Icon
            @clicked="$emit('close')"
            class="clickable">
            close
          </Icon>
        </div>
      </div>
      <h3 class="subject">
        {{ subject }}
      </h3>
      <RecordInfo :info="data.record_info"/>
    </div>
    <EditableDiv
      :value="text"
      @updated="updateNote"
       class="notes"/>
  </template>
</div>
</template>

<script>
import RecordDataMixin from '@acorns-ui/Previews/Mixins/RecordDataMixin';
import LoadingIndicator from './loadingIndicator';
import TextAvatar from '@acorns-ui/Avatars/TextAvatar';
import Icon from '@acorns-ui/Icons/FontIcon';
import EditableDiv from '@acorns-ui/Text/EditableDiv';
import RecordInfo from '@acorns-ui/Text/RecordInfo';

export default {
  name: 'NotePreview',
  mixins: [
    RecordDataMixin
  ],
  props: {
    d: { default() { return { label: 'Default label' }; } },
    prid: { type: [String, Number] },
    store: { type: String, default: 'notes'},
    dataset: { type: String, default: 'notes'},
    parent: String,
    pkey: { default: 'note_id'},
    editable: { default: true }, // parental control
  },
  data() {
    return {
      editNote: true,
    };
  },
  components: {
    Icon,
    TextAvatar,
    EditableDiv,
    LoadingIndicator,
    RecordInfo,
  },
  computed: {
    subject() {
      return this.data.document.data.subject || 'No subject provided';
    },
    text() {
      return this.data.document.data.text || 'No text provided';
    },
    addedOn() {
      return this.data.record_info.added_on;
    },
    addedBy() {
      return this.data.record_info.added_by.name;
    },
  },
  methods: {
    updateNote(text) {
      const method = 'toolSave';
      const data = { text, id: this.data.note_id };
      this.$store.dispatch('notes/postMethod', { method, data }).then(() => {
        this.$emit('updated');
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/variables';

.note-preview {
  background-color: $white;
  height: 100%;
  display: flex;
  flex-direction: column;

  &>.header {
    color: $white;
    background-color: $primary-color;
    overflow: hidden;
    position: relative;
    height: 80px;
    padding: 0px 10px 5px 10px;
  }

  .notes {
    padding: 10px;
    overflow-y: auto;
  }

}
</style>
