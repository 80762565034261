<template>
<PreviewLayout
  class="hour-preview"
  :loaded="loaded"
  @close="$emit('close')">

  <IconLink
    v-if="isEditable"
    slot="actions"
    @clicked="showHoursForm"
    class="clickable">
    edit
  </IconLink>

  <!-- <IconLink -->
  <!--   slot="actions" -->
  <!--   @clicked="showWorkForm" -->
  <!--   class="clickable"> -->
  <!--   build -->
  <!-- </IconLink> -->

  <LoadingIndicator v-if="!loaded"/>

  <template v-if="loaded">

    <div
      slot="details"
      class="hours-details">

      <InfoLine
        class="clickable"
        title="Task">
        <span>
          {{ data.task.label || 'No task label' }}
        </span>
        <Icon
          class="hidden-action"
          @clicked="showTaskPreview">
          open_in_new
        </Icon>
      </InfoLine>
      <InfoLine
        title="Worker">
        <TextAvatar :contact="data.worker"/>
      </InfoLine>
      <InfoLine
        title="Role">
        {{ data.role.label }}
      </InfoLine>
      <InfoLine
        v-if="data.property"
        title="Unit">
        <PropertyChip
          v-bind="data.property"/>
      </InfoLine>
      <InfoLine
        title="Date">
        {{ data.work_date | date }}
      </InfoLine>
      <InfoLine title="Hours">
        {{ data.hours }}
      </InfoLine>
      <InfoLine
        title="Rate">
        {{ data.rate }}
      </InfoLine>
    </div>

    <EditableDiv
      :editable="isEditable"
      class="description"
      placeholder="No notes entered"
      :value="data.notes"
      @updated="updateField('notes', $event)"/>

</template>

  </PreviewLayout>
</template>

<script>
import { fetch } from '@/api';
import RecordDataMixin from '@acorns-ui/Previews/Mixins/RecordDataMixin';
import InfoLine from '@acorns-ui/Text/InfoLine';
import IconLink from '@acorns-ui/Icons/IconLink';
import Icon from '@acorns-ui/Icons/FontIcon';
import PreviewLayout from '@acorns-ui/Layouts/PreviewLayout'
import LoadingIndicator from './loadingIndicator';
import MarkdownDiv from '@acorns-ui/Text/MarkdownDiv';
import TextAvatar from '@acorns-ui/Avatars/TextAvatar';
import PropertyChip from '@acorns-ui/Chips/PropertyChip';
import EditableDiv from '@acorns-ui/Text/EditableDiv';

export default {
  name: 'HourPreview',
  mixins: [
    RecordDataMixin,
  ],
  props: {
    d: { required: false },
    store: { type: String, default: 'hours' },
    pkey: { type: String, default: 'hour_id' },
    editable: { type: Boolean, default: true }, // parental control
  },
  data() {
    return {
      hours: [],
      followups: [],
    };
  },
  components: {
    PreviewLayout,
    IconLink,
    InfoLine,
    LoadingIndicator,
    MarkdownDiv,
    TextAvatar,
    PropertyChip,
    Icon,
    EditableDiv,
  },
  computed: {
    isLead() {
      return this.data.worker.contact_id === this.$store.getters['user/cid'];
    },
    isEditable(){
      return this.data.editable && this.editable;
    },
    activityLabel() {
      if (this.data.activity) return this.data.activity.label;
      if (this.data.task.activity) return this.data.task.activity.label;
      return 'Unknown';
    },
    taskId() {
      return this.data.task_id || this.data.task.task_id;
    },
    taskLabel() {
      return this.data.label || this.data.task.label;
    },
  },
  methods: {
    showTaskPreview() {
      this.$store.dispatch('PageModals/showModal', {
        component: 'PreviewFactory',
        params: {
          component: 'task-preview',
          prid: this.data.task.task_id,
          callback: this.refreshData,
        }
      })
    },
    showWorkForm(){
      this.$store.dispatch('PageModals/showModal', {
        component: 'WorkForm',
        params: {
        //  parent: this.parent,
        //  parentId: this.parentId,
          task: this.data.task.task_id,
          callback: this.refreshData,
        }
      })
    },
    showHoursForm(){
      this.$store.dispatch('PageModals/showModal', {
        component: 'HoursForm',
        params: {
         // parent: this.parent,
        //  parentId: this.parentId,
          id: this.fId,
          callback: this.refreshData,
        }
      })
    },
    getHistory() {
      const url = 'hours/search2';
      const args = { task_id: this.taskId };
      fetch(url, args).then((response) => {
        const rsp = response[0];
        this.hours = rsp.data;
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/variables';

.hour-preview {
  background-color: white;
  display: flex;
  flex-direction: column;

  .description {
    padding: 0.5rem;
  }
  .clickable {
    .hidden-action {
      display: none;
      margin-left: auto;
      font-size: 1.25rem;
    }
    &:hover {
      .hidden-action {
        display: inherit;
      }
    }
  }
}
</style>
