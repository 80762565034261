import Vue from 'vue';
import Vuex from 'vuex';

import navigation from '@acorns-ui/Stores/Navigation';
import Global from '@acorns-ui/Stores/Global';
import PageMessages from '@acorns-ui/Stores/PageMessages';
import PageModals from '@acorns-ui/Stores/PageModals';
import board from '@acorns-ui/Stores/Boards';
import user from '@acorns-ui/Stores/User';
import Library from '@acorns-ui/Stores/Library';

// collections is a function that can add a collection object to the store
import collection from '@acorns-ui/Stores/Collections/Mixins/collection';

import {
  actions as GetCollectionActions
} from '@acorns-ui/Stores/Collections/Mixins/GetCollectionsMixin';

import {
  actions as GetObjectActions
} from '@acorns-ui/Stores/Objects/Mixins/GetObjectsMixin';

import {
  actions as GetReportActions
} from '@acorns-ui/Stores/Reports/Mixins/GetReportsMixin';

import {
  actions as GetSearchActions
} from '@acorns-ui/Stores/Search/Mixins/GetSearchMixin';

import {
  actions as GetDatasetActions
} from '@acorns-ui/Stores/Datasets/Mixins/GetDatasetsMixin';


import deliverables from '@acorns-ui/Stores/Collections/Deliverables';
import documents from '@acorns-ui/Stores/Collections/Documents';
import tasks from '@acorns-ui/Stores/Collections/Tasks';
import issues from '@acorns-ui/Stores/Collections/Issues';
import contacts from '@acorns-ui/Stores/Collections/Contacts';
import properties from '@acorns-ui/Stores/Collections/Properties';
import projects from '@acorns-ui/Stores/Collections/Projects';
import notes from '@acorns-ui/Stores/Collections/Notes';
import files from '@acorns-ui/Stores/Collections/Files';
import communications from '@acorns-ui/Stores/Collections/Communications';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Global,
    Library,
    navigation,
    user,
    PageMessages,
    PageModals,
    tasks,
    notes,
    contacts,
    deliverables,
    issues,
    documents,
    projects,
    files,
    communications,
    roles: collection({
      collection: 'roles'
    }),
    statistics: collection({
      collection: 'statistics'
    }),
    hours: collection({
      uniqueId: 'hour_id',
      collection: 'hours',
      form: 'HoursForm',
      viewAsOptions: [
        { label: 'Rows', uid: 'rows' },
      ],
      defaultSortMethod: {
        work_date: 'desc',
      },
      batchActions: [
        { uid: 'delete', label: 'Delete records' },
        { uid: 'edit', label: 'Edit records' },
      ],
      batchEditableFields: [
        {
          label: 'Work date',
          name: 'work_date',
          type: 'date-input',
        },
        {
          label: 'Role',
          name: 'role_id',
          type: 'nested-input',
          url: 'roles/nested',
          allowSearch: true,
        },
        {
          label: 'Hours',
          name: 'hours',
          type: 'number-input',
        },
        {
          label: 'Rate',
          name: 'rate',
          type: 'number-input',
        },
      ],
    }),
    board,
  },
  state: {
    AppLabel: 'Ouzel Cloud'
  },
  mutations: {

  },
  actions: {
    ...GetCollectionActions,
    ...GetReportActions,
    ...GetObjectActions,
    ...GetDatasetActions,
    ...GetSearchActions,
  },
});
