// pulls the correct data out of fields with nested data
export function getFieldData(row, field) {
  if (field.path) {
    if (typeof (field.path) === 'string') {
      field.path = field.path.split('.');
    }
    let vlu = row[field.field || field.key];
    for (const i in field.path) {
      if (vlu) vlu = vlu[field.path[i]];
    }
    return vlu;
  }
  // key is now being used as the unqiue identifier
  // but sometimes the field is not provided so we default to key
  return row[field.field || field.key];
}

export const methods = {
  'deliverable-field': function (d) {
    return d ? d.value || '' : '';
  },
  'object-label': function (d) {
    return d.label || '';
  },
  'objects-label': function (d) {
    if(!d || !d.length) return null;
    return d[0].label || '';
  },
  'parent-label': function (d) {
    if (!d.parents) return d.label;
    return Object.values(d.parents).reverse()[0].issue_type;
  },
  'number-field': function (d) {
    return d * 1;
  },
  'money-field': function (d) {
    if(!d) return null;
    return parseFloat(d.replace(/[\$,]/g,''));
  },
  'alphanum-field': function (d) {
    if(!d) return null;
    const txt = parseFloat(d.replace(/\D/g, ''));
    return txt;
  },
  'property-field': (d) => {
    // its possible that d is an array
    if (d.length) d = d[0];
    return d.label || '';
  },
  'issue-field': d => (d ? d.label || '' : ''),
  'project-field': d => (d ? d.label || '' : ''),
  'progress-field': d => d.closed_on,
  'text-lead': d =>
    // console.log(d.label || d.contact);
    d.label || d.contact,
  'priority-field': d =>
    // id should correspond with the order
    d.priority_id * -1,
  'acres-field': d =>
    // id should correspond with the order
    d * 1,
  'hours-summary': (d) => {
    // id should correspond with the order
    if (!d) return 0;
    return d.hours * 1;
  },
};
// export default methods;
