<template>
<div class="info-tool">
  <div
    v-if="message"
    class="message">
    {{ message }}
  </div>
  <ObjectDisplay
    v-if="info"
    :data="info"/>
</div>
</template>
<script>
import ToolMixin from '@acorns-ui/Tools/Mixins/ToolMixin';
import { fetch } from '@/api';
import ObjectDisplay from '@acorns-ui/Text/ObjectDisplay';

export default {
  name: 'info-tool',
  mixins: [ ToolMixin ],
  props: {
    api: String,
    params: Object
  },
  data() {
    return {
      message: null,
      info: null,
    };
  },
  components: {
    ObjectDisplay
  },
  created() {
    // if api check
    this.getInfo();
  },
  watch: {},
  computed: {},
  methods: {
    getInfo(){
      if(!this.api) return;
      fetch(this.api)
      .then( info => this.info = info)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.info-tool  {
  background-color: $primary-color;
  color: $white;
  height: 100%;
  overflow-y: hidden;

  &:hover {
    overflow-y: auto;
  }

  .message {
    padding: 1rem;
    height: calc(100% - 2rem);
    font-size: 3rem;
  }
}
</style>
