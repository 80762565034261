import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '@/views/HomePage';

import ModuleRoute from '@acorns-ui/Modules/CollectionModule/ModuleRoute';
import CollectionPage from '@acorns-ui/Modules/CollectionModule/CollectionPage';

import templateAdminRoutes from '@acorns-ui/Modules/ProjectModule/TemplateAdminRoutes';
import projectRoutes from '@acorns-ui/Modules/ProjectModule/ProjectRoutes';
import templateRoutes from '@acorns-ui/Modules/ProjectModule/TemplateRoutes';
import AdminRoutes from '@acorns-ui/Modules/AdminModule/AdminRoutes';
import LibraryRoutes from '@acorns-ui/Modules/LibraryModule/LibraryRoutes';

//import LoginRoute from './LoginRoute';
import LoginRoute from '@acorns-ui/Modules/Login/LoginRoute';
import globalRouteGuard from './routeGuard';
import CollectionRoute from '@acorns-ui/Modules/CollectionModule/CollectionRoute';
//import ContactsRoutes from '@acorns-ui/Modules/ContactsModule/ContactsRoutes';


Vue.use(Router);

const CommunicationsRoutes = ModuleRoute({
  module: 'communications',
  pkey: 'communication_id',
  title: 'Communications'
});

const DocumentsRoutes = ModuleRoute({
  module: 'documents',
  pkey: 'document_id',
  title: 'Documents'
});

const IssuesRoutes = ModuleRoute({
  module: 'issues',
  pkey: 'issue_id',
  title: 'Issues'
});

const ContactsRoutes = ModuleRoute({
  module: 'contacts',
  pkey: 'contact_id',
  title: 'Contacts'
});


const TasksRoutes = ModuleRoute({
  module: 'tasks',
  pkey: 'task_id',
  title: 'Tasks'
});

const ClientsRoutes = ModuleRoute({
  module: 'clients',
  pkey: 'client_id',
  title: 'Clients'
});

const StatisticsRoutes = ModuleRoute({
  module: 'statistics',
  pkey: 'client_statistic_id',
  title: 'Statistics'
});

const FilesRoutes = ModuleRoute({
  module: 'files',
  pkey: 'file_id',
  title: 'Files',
  preview: require('@acorns-ui/Previews/FilePreview').default,
});



const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    LoginRoute,
    AdminRoutes,
//    PropertyRoutes,
//    PropertyTypeRoutes,
    projectRoutes,
    templateAdminRoutes,
    templateRoutes,
    ContactsRoutes,
    CommunicationsRoutes,
    DocumentsRoutes,
    FilesRoutes,
    IssuesRoutes,
    TasksRoutes,
    ClientsRoutes,
    StatisticsRoutes,
    LibraryRoutes,
    {
      path: '/',
      name: 'home',
      components: {
        page: HomePage
      },
    },
    {
      path: '/:module',
      components: { page: CollectionPage },
      props: {
        page: true
      },
    },
  ],
});

router.beforeEach(globalRouteGuard);

export default router;
