import Vue from 'vue';
// Register global custom directives

Vue.directive('clickoutside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line
    el.event = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };

    document.body.addEventListener('click', el.event);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.event);
  },
});
