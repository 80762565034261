<template>
<div class="modal-standin">
  <h1>FAKE MODAL FOR DEV #{{ position }}</h1>
  <p>
    This is a fake modal for use in development
  </p>
  <button
    @click="openAnotherModal">
    Open another
  </button>
</div>
</template>
<script>

export default {
  name: 'ModalStandin',
  props: {
    position: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  components: {},
  created() {},
  watch: {},
  computed: {},
  methods: {
    openAnotherModal(){
      this.$store.dispatch('PageModals/showModal', {
        component: 'ModalStandin',
        params: { position: this.position+1 }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.modal-standin  {
  min-width: 50rem;
  min-height: 30rem;
  padding: 1rem;
}
</style>
