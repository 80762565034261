<template>
  <div class="missing-tool-message">
    TOOL NOT FOUND
</div>
</template>
<script>

export default {
  name: 'missing-tool-message',
  props: {},
  data() {
    return {};
  },
  components: {},
  created() {},
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.missing-tool-message  {

}
</style>
