// provide access to the store
import store from '@/store';

const setPageTitle = to => {
  const { title, source } = to.meta;
  if(title) {
    store.commit('navigation/SET_PAGE_TITLE', title);
  }
  if(source == 'workspace'){
    store.commit('navigation/SET_WORKSPACE_BREADCRUMBS', [{ source, title }]);
  }
  if(source == 'page'){
    store.commit('navigation/SET_PAGE_BREADCRUMBS', [{ source, title }]);
  }
};



export default function (to, from, next) {
  //const loggedIn = store.state.user.loggedIn;
  // this were we can do stuff for each route
  // like check to see if we are logged in
  //console.log('Current route in route guard', to);
  const url = to.fullPath;
  //return next();
  if (to.path === '/login') {
	  next();
  } else {
	  store.dispatch('user/check')
	    .then( loggedIn => {
		    if(loggedIn) {
		      setPageTitle(to);
		      next();
		    } else {
		      next({
			      path: '/login',
			      query: { url }
		      });
		    }
	    })
	    .catch( () => {
		    // not logged in
		    next({
		      path: '/login',
		      query: { url }
		    });
	    });
  }
}
