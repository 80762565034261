<template>
<BoardWorkspace
  v-if="loaded"
  class="home-page"
  :parent="boardType"
  :id="currentBoardId"
  :store="roleStore"
  :url="url"
  :center="true">
</BoardWorkspace>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import BoardWorkspace from '@acorns-ui/Workspaces/BoardWorkspace';

export default {
  name: 'HomePage',
  props: {},
  data() {
    return {
      loaded: false,
    };
  },
  components: {
    BoardWorkspace,
  },
  created() {
    if(this.currentRoleId) {
      this.getRoleBoard(this.currentRoleId);
    }
  },
  watch: {
    currentRoleId(a,b) {
      this.getRoleBoard(a);
    }
  },
  computed: {
    ...mapGetters('user', [
      'cid',
      'id',
      'currentRole',
    ]),
    ...mapState('user', [
      'currentRoleId',
      'boardType',
    ]),
    currentBoardId(){
      if(this.boardType == 'role') {
        return this.currentRoleId;
      } else {
        // the user should not have an id
        return '';//this.id;
      }
    },
    roleStore() {
      var name = `role${this.currentRoleId}`;
      if(typeof(this.$store.state[name]) == 'object') {
        return name;
      } else {
        return null;
      }
    },
    url(){
      if(this.boardType == 'role') {
        return `role/userboard/${this.currentRoleId}`;
      } else {
        return `user/board`;
      }
    }
  },
  methods: {
    getRoleBoard(id) {
      this.$store.dispatch('getRole', { id })
        .then( res => {
          console.log('res', res);
          this.loaded = true
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.home-page  {

}
</style>
